import React, { useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import { useItems } from "../../../hooks/useItems";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Block } from "./Block";

const Column =
  ({ onClick, onClickFrame, items, cacheBuster }) =>
  ({ index, style }) =>
    (
      <Block
        style={style}
        key={index}
        cacheBuster={cacheBuster}
        onSelect={(e) => {
          e.stopPropagation();
          onClick(index);
        }}
        onSelectFrame={() => {
          onClickFrame(index);
        }}
        {...items[index]}
      />
    );

const itemsRef = React.createRef();
export const ItemList = ({ scrollIndex, onClick, onClickFrame, items, cacheBuster }) => {
  const { width } = useWindowSize();
  const { count, status } = useItems();

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.scrollToItem(scrollIndex);
    }
  }, [scrollIndex, items]);

  return (
    <div className="mt-8 md:mt-0">
      <List
        key={status}
        height={150}
        itemCount={count}
        itemSize={width < 640 ? 240 : 300}
        layout="horizontal"
        ref={itemsRef}
        width={width ? width - 32 : 900}
      >
        {Column({ onClick, onClickFrame, items, cacheBuster })}
      </List>
    </div>
  );
};
