import React, { useEffect } from "react"
import useVideo from "../../../hooks/useVideo"
import styled from "styled-components"

const VideoEl = styled.video.attrs((props) => ({
  style: {
    width: `${props.width}px`,
    height: `${props.height}px`,
  }
}))``

const Video = props => {
  const { options, ...rest } = props
  const { width, height, isPaused, videoId, ...moreConfig } = options
  const videoRef = useVideo(options)

  useEffect(() => {
    if (isPaused) {
      videoRef.current.pause()
    } else {
      let playPromise = videoRef.current.play()
      if (playPromise !== undefined) {
        playPromise
          .then((value) => {
          })
          .catch((err) => {
          })
      }
    }
  }, [isPaused])

  return <VideoEl key={`videoEl_${videoId}`} ref={videoRef} {...rest} width={width} height={height} />
}

export default Video
