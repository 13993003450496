export const links = [
  {
    path: "/",
    label: "Video",
  },
  {
    path: "/artists",
    label: "Artists",
  },
  {
    path: "/about",
    label: "About",
  },
];
