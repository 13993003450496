import React, { useEffect, useRef } from "react"
import useCanvas from "../../../hooks/useCanvas"

const Canvas = props => {
  const { draw, options, ...rest } = props
  const { context, width, height, ...moreConfig } = options
  const canvasRef = useCanvas(draw, {context, width, height})

  return <canvas ref={canvasRef} {...rest} width={width} height={height} className={`pixelated`} />
}

export default Canvas
