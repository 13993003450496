import Stylewind from "./Stylewind";

export const Text = Stylewind``;
Text.defaultProps = {
  as: "span",
  className: "text-white text-lg",
  variantClassNames: {
    blue: "text-blue-700 text-lg",
  },
};

export default Text;
