import React from "react";
import Layout from "../components/Layout";
import Stylewind from "../components/Stylewind";
import playImg from "../images/play.svg";
import { Text } from "../components/Text";
import { Link } from "../components/Link";
import { Heading } from "../components/Heading";

const Container = Stylewind``;
Container.defaultProps = {
  className: "flex flex-col md:flex-row mx-7",
};

const LeftColumn = Stylewind``;
LeftColumn.defaultProps = {
  className:
    "w-content md:w-1/2 flex flex-col items-left justify-start mx-9 mt-10",
};

const RightColumn = Stylewind``;
RightColumn.defaultProps = {
  className: "flex justify-center text-center w-content md:w-1/2 mb-8 md:mb-0",
};

const Hero = Stylewind`
  max-width: 400px;
  max-height: 400px;
`;
Hero.defaultProps = {
  className: "rounded-full w-full h-auto mt-12 border-white border-4",
  as: "img",
};

const Landing = () => {
  return (
    <Layout>
      <Container>
        <LeftColumn>
          <Heading as="h2" addClassName="mb-3">
            About The Project
          </Heading>
          <Text addClassName="opacity-50">
            <p className={`mb-3`}>
              The <strong>Owl Together Project</strong> aims to produce a symbol
              of the collective energy, efforts, and perspectives that are
              powering Twilio.
            </p>
            <p className={`mb-3`}>
              It takes everyone here to produce the kind of magical and enduring
              force we can be.
            </p>
            <p>
              'Drawing the Owl' has been a essential mantra in our halls – and
              wherever else challenges have been faced. No matter what we've
              achieved, nothing compares to the potential ahead of us and what
              we can accomplish when we come together!
            </p>
          </Text>
          <Link to="/" addClassName="mt-8 mb-16">
            <img src={playImg} alt="" width={30} height={30} />
            <Text as="span" addClassName="text-lg font-semibold normal-case">
              Watch Video
            </Text>
          </Link>
          <Heading as="h3" variant="blue">
            NNFT
          </Heading>
          <Text addClassName="opacity-50 mb-12">
            An NNFT is philosophically similar to an NFT (non-fungible token),
            but instead of being on the blockchain and belonging to a single
            owner, it belongs to everyone at Twilio.
          </Text>
        </LeftColumn>
        <RightColumn>
          <Hero src="/images/Twilioville-CCH.jpg" />
        </RightColumn>
      </Container>
    </Layout>
  );
};

export default Landing;
