import React from "react";
import Stylewind from "../Stylewind";
import Logo from "./logo.svg";
import Nav from "../Nav";
import { useLocation } from "react-router-dom";
import { links } from "../../constants/links";

export const Container = Stylewind`
  height: 117px;
`;

Container.defaultProps = {
  className:
    "p-8 pt-4 md:pt-10 bg-white flex flex-col md:flex-row justify-between items-start md:items-end",
};
const Heading = Stylewind``;
Heading.defaultProps = {
  as: "h1",
  className:
    "text-sm md:text-normal text-red-500 normal-case flex font-semibold items-center mb-4 md:mb-0",
};

// A component that displays a heading and basic navigation
const Header = (props) => {
  const { pathname } = useLocation();

  return (
    <Container>
      <Heading>
        <img
          src={Logo}
          width={45}
          height={45}
          className="mr-4"
          alt="Twilio Hero Verse"
        />
        Owl Together 2022
      </Heading>
      <Nav>
        {links.map((link) => (
          <Nav.Item
            key={link.path}
            to={link.path}
            isActive={link.path === pathname}
          >
            {link.label}
          </Nav.Item>
        ))}
      </Nav>
    </Container>
  );
};

export default Header;
