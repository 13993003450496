import React, { useLayoutEffect, useState } from "react"
import Header from "../Header";
import Footer from "../Footer";
import Stylewind from "../Stylewind";
import { useTailwindBreakpoint } from "../../hooks/useTailwindBreakpoint"

const Wrapper = Stylewind`
`

const Container = Stylewind`
  &>:nth-child(2) {
    display: flex;
    flex-grow: 3;
  }
`;

Container.defaultProps = {
  className: "flex flex-col min-h-full h-full min-w-screen w-screen",
};

const Content = Stylewind``;
Content.defaultProps = {
  as: "main",
};

const Layout = ({ children }) => {
  const breakpoint = useTailwindBreakpoint();
  const [wrapperClasses, setWrapperClasses] = useState("")

  useLayoutEffect(() => {
    if (breakpoint) {
      // Desktop
      setWrapperClasses("min-h-screen h-screen min-w-screen w-screen overflow-x-hidden")
    } else {
      // Mobile
      setWrapperClasses("absolute top-0 right-0 bottom-0 left-0 overflow-y-auto overflow-x-hidden")
    }
  }, [breakpoint])

  return (
    <Wrapper className={wrapperClasses}>
      <Container>
        <Header />
        <Content>{children}</Content>
        <Footer />
      </Container>
    </Wrapper>
  );
};

export default Layout;
