import React, { useEffect, useRef, useState } from "react"
import Canvas from "../Canvas"
import Stylewind from "../../Stylewind"
import Video from "../Video"
import styled from "styled-components"

const Container = styled.div.attrs((props) => ({
  style: {
    width: `100%`,
    height: `${props.height}px`,
  }
}))``
Container.defaultProps = {
  className: "w-full relative bg-black"
}

const InnerContainer = styled.div.attrs((props) => ({
  style: {
    left: `${props.videoRect.x}px`,
    top: `${props.videoRect.y}px`,
    width: `${props.videoRect.width}px`,
    height: `${props.videoRect.height}px`,
  }
}))``
const AspectContainer = Stylewind``
AspectContainer.defaultProps = {
  className: "relative w-full h-full"
}
const BackgroundVideoContainer = Stylewind``
BackgroundVideoContainer.defaultProps = {
  className: "absolute z-0"
}
const PixelatedCanvasContainer = Stylewind`
 > canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  width: 100%;
  height: 100%;
 }
`
PixelatedCanvasContainer.defaultProps = {
  className: "absolute top-0 right-0 bottom-0 left-0 z-10"
}
const CanvasContainer = Stylewind``
CanvasContainer.defaultProps = {
  className: "absolute top-0 right-0 bottom-0 left-0 z-10"
}

const IdleAnimation = ({ canvasImageFilenames, visibleFrameIndex, width, height, videoRect, isPaused}) => {
  const sleepingOwlFramePrefix = "sleeping"
  const sleepingOwlFaceFramePrefix = "sleepingFace"
  const canvasFrameRate = 10
  const owlFrameRate = 30
  const sleepingOwlFrameCount = 2

  let backgroundVideoRef = useRef(null)
  let canvasFramesRef = useRef(null)
  let canvasFramesLoadedCount = useRef(0)
  let sleepingOwlFramesRef = useRef(Array(sleepingOwlFrameCount).fill(0))
  let sleepingOwlFaceFramesRef = useRef(Array(sleepingOwlFrameCount).fill(0))

  let sleepingOwlFramesLoadedCount = useRef(0)
  let sleepingOwlFaceFramesLoadedCount = useRef(0)
  let canvasFrame = useRef(0)
  let owlFrame = useRef(0)
  let lastLoadedCanvasImg = useRef(null)
  const [videoCurrentTime, setVideoCurrentTime] = useState(null)

  const videoCanvasSize = useRef({
    width: 960,
    height: 540
  })

  const pixelatedCanvasSize = useRef( {
    width: 256,
    height: 144
  } )

  const drawOwl = (ctx, frameCount) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    if (sleepingOwlFramesLoadedCount.current == sleepingOwlFrameCount && sleepingOwlFaceFramesLoadedCount.current == sleepingOwlFrameCount) {
      let owlFrameImg = sleepingOwlFramesRef.current[owlFrame.current]

      if (canvasFramesLoadedCount.current > 0) {
        try {
          let canvasImg = visibleFrameIndex ? canvasFramesRef.current[visibleFrameIndex] : canvasFramesRef.current[canvasFrame.current]
          if (canvasImg) {
            ctx.globalCompositeOperation = "source-over"
            ctx.fillStyle = "#ffffff"
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
            ctx.drawImage(canvasImg, 0, 0, ctx.canvas.width, ctx.canvas.height)
            lastLoadedCanvasImg.current = canvasImg
          } else {
            ctx.globalCompositeOperation = "source-over"
            ctx.fillStyle = "#ffffff"
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
          }
          if (owlFrameImg) {
            ctx.globalCompositeOperation = "destination-in"
            ctx.drawImage(owlFrameImg, 0, 0, ctx.canvas.width, ctx.canvas.height)
          }

          if (frameCount % owlFrameRate == 0 && !isPaused) {
            owlFrame.current += 1
            if (owlFrame.current > sleepingOwlFrameCount - 1) {
              owlFrame.current = 0
            }
          }

          if (frameCount % canvasFrameRate == 0 && !isPaused) {
            canvasFrame.current += 1
            if (canvasFrame.current > canvasFramesLoadedCount.current - 1) {
              canvasFrame.current = 0
            }
          }

        } catch (e) {
          console.error(e)
        }
      }
    }
  }

  const drawOwlFace = (ctx, frameCount) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.globalCompositeOperation = "source-over"
    if (sleepingOwlFaceFramesLoadedCount.current == sleepingOwlFrameCount) {
      try {
        let owlFaceFrameImg = sleepingOwlFaceFramesRef.current[owlFrame.current]
        if (owlFaceFrameImg) {
          ctx.globalCompositeOperation = "source-over"
          ctx.drawImage(owlFaceFrameImg, 0, 0, ctx.canvas.width, ctx.canvas.height)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const drawBackground = (ctx, frameCount) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.globalCompositeOperation = "source-over"
    ctx.fillStyle = "#000000"
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    if (sleepingOwlFramesLoadedCount.current == sleepingOwlFrameCount && sleepingOwlFaceFramesLoadedCount.current == sleepingOwlFrameCount) {
      try {
        let owlFrameImg = sleepingOwlFramesRef.current[owlFrame.current]
        if (backgroundVideoRef.current) {
          ctx.drawImage(backgroundVideoRef.current, 0, 0, ctx.canvas.width, ctx.canvas.height)
        }
        if (owlFrameImg) {
          ctx.globalCompositeOperation = "destination-out"
          ctx.drawImage(owlFrameImg, 0, 0, ctx.canvas.width, ctx.canvas.height)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onBackgroundVideoCanPlay = evt => {
    let target = evt.currentTarget
    backgroundVideoRef.current = target
    if (!isPaused) {
      let playPromise = backgroundVideoRef.current.play()
      if (playPromise !== undefined) {
        playPromise
          .then((value) => {
          })
          .catch((err) => {
          })
      }
    }
  }

  useEffect(() => {
    let isMounted = true

    const loadCanvasImage = (filename, index, onComplete, onError) => {
      let filePath = `${filename}`
      let img = new Image()
      img.id = filename
      img.src = filePath
      img.onload = evt => {
        onComplete(evt, img, index)
      }
      img.onerror = evt => {
        onError(evt)
      }
    }
    const loadCanvasImages = () => {
      canvasFramesRef.current = Array(canvasImageFilenames.length).fill(0)
      let i = 0
      while (i < canvasImageFilenames.length) {
        canvasFramesRef.current[i] = null
        let imagename = canvasImageFilenames[i]
        loadCanvasImage(imagename, i, (evt, img, index) => {
          if (isMounted) {
            canvasFramesRef.current[index] = img
            canvasFramesLoadedCount.current += 1
          }
        }, (evt) => {
          console.error(evt)
        })
        i++
      }
    }

    const loadOwlImage = (fileNamePrefix, index, onComplete, onError) => {
      let filePath = `/nnft-prototype/owl-animation/${fileNamePrefix}.png`
      let img = new Image()
      img.id = fileNamePrefix
      img.src = filePath
      img.onload = evt => {
        onComplete(evt, img, index)
      }
      img.onerror = evt => {
        onError(evt)
      }
    }
    const loadOwlFrames = () => {
      let i = 0
      while (i < sleepingOwlFrameCount) {
        let owlFrameName = `${sleepingOwlFramePrefix}${i < 10 ? "0" + (i + 1) : (i + 1)}`
        let owlFaceFrameName = `${sleepingOwlFaceFramePrefix}${i < 10 ? "0" + (i + 1) : (i + 1)}`

        if (sleepingOwlFramesLoadedCount.current < sleepingOwlFrameCount) {
          loadOwlImage(owlFrameName, i, (evt, img, index) => {
            if (isMounted) {
              sleepingOwlFramesRef.current[index] = img
              sleepingOwlFramesLoadedCount.current += 1
            }
          }, (evt) => {
            console.error(evt)
          })
        }
        if (sleepingOwlFaceFramesLoadedCount.current < sleepingOwlFrameCount) {
          loadOwlImage(owlFaceFrameName, i, (evt, img, index) => {
            if (isMounted) {
              sleepingOwlFaceFramesRef.current[index] = img
              sleepingOwlFaceFramesLoadedCount.current += 1
            }
          }, (evt) => {
            console.error(evt)
          })
        }

        i++
      }
    }

    // console.log(`Begin idle animation…`)
    loadOwlFrames()
    loadCanvasImages()

    return () => {
      isMounted = false
      // console.log(`Clean up IdleAnimation…`)
    }
  }, [canvasImageFilenames])

  useEffect(() => {
    if (!backgroundVideoRef.current) {
      return
    }
    if (isPaused) {
      setVideoCurrentTime(backgroundVideoRef.current.currentTime)
    } else {
      backgroundVideoRef.current.currentTime = videoCurrentTime
    }
  }, [isPaused])

  return (
    <Container width={width} height={videoRect.height}>
      <InnerContainer videoRect={videoRect} className={`w-full absolute bg-black`}>
        <AspectContainer>
          <BackgroundVideoContainer className={"invisible"}>
            <Video key={"backgroundVideo"} options={{
              videoSrc: "/nnft-prototype/video/Owl-Together-Background-30s.mp4",
              onVideoCanPlay: onBackgroundVideoCanPlay,
              width: videoCanvasSize.current.width,
              height: videoCanvasSize.current.height,
              isPaused: isPaused,
              videoId: "backgroundVideo"
            }}/>
          </BackgroundVideoContainer>
          <PixelatedCanvasContainer>
            <Canvas draw={drawBackground} options={{
              context: "2d",
              width: pixelatedCanvasSize.current.width,
              height: pixelatedCanvasSize.current.height
            }}/>
          </PixelatedCanvasContainer>
          <PixelatedCanvasContainer>
            <Canvas draw={drawOwl} options={{
              context: "2d",
              width: pixelatedCanvasSize.current.width,
              height: pixelatedCanvasSize.current.height
            }}/>
          </PixelatedCanvasContainer>
          <PixelatedCanvasContainer>
            <Canvas draw={drawOwlFace} options={{
              context: "2d",
              width: pixelatedCanvasSize.current.width,
              height: pixelatedCanvasSize.current.height
            }}/>
          </PixelatedCanvasContainer>
        </AspectContainer>
      </InnerContainer>
    </Container>
  )
}

export default IdleAnimation
