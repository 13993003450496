import React, { createContext, useRef, useState } from "react"
import { useLoadItems } from "../hooks/useLoadItems";

export const AppContext = createContext();

const ItemsProvider = ({ children }) => {
  const { frames, status, count } = useLoadItems();
  let cacheBuster = useRef(new Date().getTime())

  return (
    <AppContext.Provider value={{ frames, status, count, cacheBuster }}>
      {children}
    </AppContext.Provider>
  );
};

export default ItemsProvider;
