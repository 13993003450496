import React from "react";
import Stylewind from "../Stylewind";
import Nav from "../Nav";
import { useLocation } from "react-router-dom";
import { links } from "../../constants/links";

export const Container = Stylewind`
  height: 170px;
`;
Container.defaultProps = {
  className:
    "p-9 flex justify-center items-center flex flex-col text-white font-callout text-xl bg-blue-900",
};

const FooterText = Stylewind``;
FooterText.defaultProps = {
  as: "span",
  className: "mt-12 text-blue-500",
};

// A component that displays a heading and basic navigation
const Footer = (props) => {
  const { pathname } = useLocation();

  return (
    <Container>
      <Nav variant="footer">
        {links.map((link) => (
          <Nav.Item
            key={link.path}
            to={link.path}
            isActive={link.path === pathname}
          >
            {link.label}
          </Nav.Item>
        ))}
      </Nav>
      <FooterText>© Twilio 2022, NNFT</FooterText>
    </Container>
  );
};

export default Footer;
