import { useRef, useEffect } from "react"

const useVideo = (options={}) => {
  const videoRef = useRef(null)

  useEffect(() => {
    let isMounted = true
    const video = videoRef.current
    const { videoSrc, onVideoCanPlay, isPaused } = options

    const loadVideo = () => {
      video.preload = "auto"
      video.autoplay = false
      video.loop = true
      video.muted = true
      if (!isPaused || (video.src && video.src.indexOf(videoSrc) === -1)) {
        video.src = videoSrc
      }

      video.oncanplay = evt => {
        if (isMounted) {
          onVideoCanPlay(evt)
        }
      }
    }

    loadVideo()

    return () => {
      isMounted = false
    }
  }, [options])

  return videoRef
}

export default useVideo
