import React, { useEffect, useLayoutEffect, useState } from "react"
import Layout from "../../components/Layout";
import Stylewind from "../../components/Stylewind";
import { useItems } from "../../hooks/useItems";
import { useQueryState } from "../../hooks/useQuery";
import Modal from "./components/Modal";
import { ItemList } from "./components/ItemList";
import GlobalErrorBoundary from "../../components/GlobalErrorBoundary";
import ErrorDialog from "../../components/ErrorDialog";
import { TimelineSelector } from "./components/TimelineSelector";
import IdleAnimation from "../../components/NNFT/IdleAnimation";
import { useWindowSize } from "../../hooks/useWindowSize";
import styled from "styled-components";

const VideoContent = Stylewind``;
VideoContent.defaultProps = {
  className: "bg-white w-full px-7",
};

const VideoContainer = styled.div.attrs((props) => ({
  style: {
    height: `${props.height}px`,
  },
}))``;

const VideoPage = () => {
  const { width, height } = useWindowSize();
  const [videoRect, setVideoRect] = useState({
    x: 0,
    y: 0,
    width: 1280,
    height: 720,
  });
  let [scrollIndex, setScrollIndex] = useQueryState("scrollIndex", 0);
  let [visibleFrame, setVisibleFrame] = useQueryState(
    "visibleFrame",
    undefined
  );
  let [visibleFrameIndex, setVisibleFrameIndex] = useState(undefined)
  const [canvasImageFilenames, setCanvasImageFilenames] = useState([]);
  const [isVideoPaused, setIsVideoPaused] = useState(false)

  let { frames, status, count, cacheBuster } = useItems();

  const localFrame = frames && frames.find((item) => item?.id === visibleFrame);

  const onClickVideoFrame = () => {
    setIsVideoPaused(prevState => {
      if (prevState) {
        setVisibleFrameIndex(null)
      }
      return !prevState
    })
  }

  const onModalClosed = () => {
    if (!isVideoPaused) {
      // Try to restart the animation
      setIsVideoPaused(true)
      setIsVideoPaused(false)
    }
  }

  useEffect(() => {
    let imageFilenames = frames.map(frame => {
      return `${process.env.REACT_APP_S3_PREFIX}spaces/space_${frame.id}.png?cache=${cacheBuster.current}`
    })
    setCanvasImageFilenames(imageFilenames)
  }, [frames])

  useLayoutEffect(() => {
    const aspectRatio = 9 / 16;
    const hMargin = 56;
    const minHeightForTopUI = 120;
    const minHeightForBottomUI = 320;
    const minHeightForVideo = 120;

    const expectedHeight = width * aspectRatio;
    const maxVideoHeight = height - (minHeightForTopUI + minHeightForBottomUI);
    if (isNaN(maxVideoHeight)) {
      return;
    }
    const videoHeight = Math.max(
      minHeightForVideo,
      Math.ceil(Math.min(expectedHeight, maxVideoHeight))
    );
    const videoWidth = Math.ceil(videoHeight / aspectRatio - hMargin);

    const finalWidth = Math.max(1, Math.max(videoWidth, videoHeight));
    const finalHeight = Math.max(1, Math.min(videoWidth, videoHeight));

    setVideoRect({
      x: Math.max(
        0,
        Math.ceil(width / 2 - finalWidth / 2) - Math.ceil(hMargin / 2)
      ),
      y: 0,
      width: finalWidth,
      height: finalHeight,
    });
  }, [width, height]);

  return (
    <Layout>
      <GlobalErrorBoundary>
        <>
          {status === "error" && (
            <ErrorDialog
              headingText="Error loading frames"
              bodyText="We've encountered a problem loading data. We're working to resolve this issue!"
            />
          )}

          <Modal
            isOpen={visibleFrame !== undefined}
            onClose={() => {
              setVisibleFrame(undefined)
              onModalClosed()
            }}
            frame={visibleFrame}
            cacheBuster={cacheBuster.current}
            {...localFrame}
          />
          <VideoContent>
            <VideoContainer
              height={videoRect.height}
              className={`w-full bg-black cursor-pointer`}
              onClick={onClickVideoFrame}
            >
              <IdleAnimation
                canvasImageFilenames={canvasImageFilenames}
                visibleFrameIndex={visibleFrameIndex}
                width={width}
                height={height}
                videoRect={videoRect}
                isPaused={isVideoPaused}
              />
            </VideoContainer>
            <TimelineSelector
              onSelect={({ index, maxIndex }) => {
                if (index === 0) {
                  setScrollIndex(0);
                } else if (index === maxIndex - 1) {
                  setScrollIndex(9999);
                } else {
                  // Algebra (solve for x):
                  // e.g.: 5/100 = x/1000; 100x = 5000; x = 500;
                  const nextIndex = (index * frames.length) / maxIndex;
                  setScrollIndex(Math.floor(nextIndex));
                }
              }}
            />
            <ItemList
              scrollIndex={scrollIndex}
              items={frames}
              cacheBuster={cacheBuster.current}
              onClick={(index) => {
                if (visibleFrame === frames[index].id) {
                  setVisibleFrame(undefined)
                  setVisibleFrameIndex(null)
                } else {
                  setVisibleFrame(frames[index].id)
                  setVisibleFrameIndex(index)
                  setIsVideoPaused(true)
                }
              }}
              onClickFrame={(index) => {
                if (visibleFrameIndex === index) {
                  setVisibleFrame(undefined)
                  setVisibleFrameIndex(null)
                  setIsVideoPaused(false)
                } else {
                  setVisibleFrameIndex(index)
                  setIsVideoPaused(true)
                }
              }}
            />
          </VideoContent>
        </>
      </GlobalErrorBoundary>
    </Layout>
  );
};

export default VideoPage;
