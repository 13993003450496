import React from "react";
import Text from "./Text";
import Heading from "./Heading";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: Potentially add to logging service / sentry / etc.
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full flex flex-col items-center justify-center">
          <div className="flex-col bg-white rounded-md p-10">
            <Heading variant="modal">Sorry</Heading>
            <div className="mt-4">
              <p className="text-slate-500">
                We've encountered an error. Please try again later.
              </p>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
