import React from "react";
import defaultFallback from "./loading-avatar.png";
import classnames from "classnames";
const ImageWithFallback = ({
  src,
  fallbackSrc = defaultFallback,
  className,
  type = "image/png",
  ...rest
}) => {
  // We're going to use a ref in a slightly unorthodox way.
  // If the src fails, it'll load the fallback image.
  // We flip out the src on image hover in some instances BUT we don't want
  // to try to reload the image on mouseOut. If this ref has a value,
  // it means the image failed to load so next time, we should just jump right to the fallback.
  const failedSrc = React.useRef();

  return (
    <img
      alt=""
      src={failedSrc.current === src ? fallbackSrc : src}
      className={classnames(className, "pixelated")}
      {...rest}
      onError={({ currentTarget }) => {
        failedSrc.current = src;
        currentTarget.onerror = null;
        currentTarget.src = fallbackSrc;
      }}
    />
  );
};

export default ImageWithFallback;
