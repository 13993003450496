import React from "react";
import Text from "../../../components/Text";
import { useTailwindBreakpoint } from "../../../hooks/useTailwindBreakpoint";
import { Content } from "./ArtistDisplay";

const SectionHeading = () => (
  <div className="w-full md:w-1/2 mb-2 mt-10">
    <Content>
      <Text variant="blue" addClassName="text-sm pl-4">
        Alias
      </Text>
      <Text variant="blue" addClassName="text-sm">
        Slack
      </Text>
      <Text variant="blue" addClassName="text-sm">
        Frame
      </Text>
    </Content>
  </div>
);

export const ArtistHeadings = () => {
  const breakpoint = useTailwindBreakpoint();
  return (
    <div className="flex w-full">
      {breakpoint !== undefined && <SectionHeading />}
      <SectionHeading />
    </div>
  );
};
