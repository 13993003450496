import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import Stylewind from "../../../components/Stylewind";
import Text from "../../../components/Text";
import Heading from "../../../components/Heading";
import ImageWithFallback from "../../../components/ImageWithFallback";

const Overlay = Stylewind``;
Overlay.defaultProps = {
  as: "div",
  className:
    "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0",
};

const ModalCardBase = Stylewind``;
ModalCardBase.defaultProps = {
  as: "div",
  className:
    "inline-block align-bottom rounded-lg px-2 pt-2 pb-2 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-2 bg-white relative overflow-visible",
};
const ModalCard = Stylewind`
  min-width: 800px;

  @media (max-width: 812px) {
    min-width: 90vw;
    max-width: 90vw;
    top: -200px;
  }
`;
ModalCard.defaultProps = {
  as: "div",
  className:
    "inline-block align-bottom rounded-lg px-2 pt-2 pb-2 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-2 bg-white relative overflow-visible",
};

const ModalBody = Stylewind``;
ModalBody.defaultProps = {
  as: "div",
  className: "flex flex-col justify-center",
};

const Image = Stylewind``;
Image.defaultProps = {
  as: "img",
  className: "w-full h-auto rounded-lg pixelated",
};

const Details = Stylewind``;
Details.defaultProps = {
  className: "flex flex-col w-full",
};

const Content = Stylewind``;
Content.defaultProps = {
  as: "div",
  className: "flex flex-row w-full relative pl-2",
};

const TextContainer = Stylewind``;
TextContainer.defaultProps = {
  className: "flex flex-col pl-8",
};

const Subheading = Stylewind``;
Subheading.defaultProps = {
  as: "div",
  className: "flex flex-row space-x-5 items-center",
};

const FrameNumber = Stylewind``;
FrameNumber.defaultProps = {
  as: "div",
  className: "flex flex-col absolute top-8 right-8 items-end",
};

const Close = Stylewind``;
Close.defaultProps = {
  as: "button",
  className:
    "absolute -top-3 -right-3 bg-white rounded-full w-10 h-10 flex justify-center items-center shadow-lg",
};

export default function Modal({
  id,
  isOpen,
  onClose,
  name: title,
  slack: handle,
  pronouns: attributes,
  bio: description,
  frame,
  cacheBuster,
  ...rest
}) {
  const [open, setOpen] = useState(undefined);

  // We want to set this up so we're using isOpen as a default.
  // we're initially setting the value to whatever is in isOpen.
  // If the state has a value, we flip over to that.
  let localIsOpen = isOpen;
  if (open !== undefined) {
    localIsOpen = open;
  }

  const avatarUrl = `${process.env.REACT_APP_S3_PREFIX}heroes/attendee-${id}.png?cache=${cacheBuster}`;
  const frameImage = `${process.env.REACT_APP_S3_PREFIX}spaces/space_${id}.png?cache=${cacheBuster}`;
  const localHandle =
    handle && (handle.indexOf("@") > -1 ? handle : `@${handle}`);

  // This is the local portion of the close.
  // With this, we'll fire off the start of the closing animation
  // and only alert the parent after the close is finished.
  const handleClose = () => {
    setOpen(false);
  };

  // When the animation is complete. We want to call this modal
  // "officially closed"
  const handleCloseComplete = () => {
    onClose && onClose();
    // reset the local open state and
    // let parent drive whether this is open or not
    setOpen(undefined);
  };

  return (
    <Transition.Root show={localIsOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <Overlay onClick={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            afterLeave={handleCloseComplete}
          >
            <ModalCard>
              <Close onClick={handleClose}>
                <XIcon className="h-5 w-5 text-slate-500" />
              </Close>
              <ModalBody>
                <Image src={frameImage} />
                <Details>
                  <Content>
                    <ImageWithFallback
                      src={avatarUrl}
                      className="w-40 h-40 mt-10 mb-6 pixelated"
                    />
                    <TextContainer>
                      <Heading
                        variant="modal"
                        addClassName="mt-10 text-sm md:text-3xl"
                      >
                        {title}
                      </Heading>
                      <Subheading>
                        <Text variant="blue">{localHandle}</Text>
                        <Text variant="blue" addClassName="text-sm">
                          {attributes}
                        </Text>
                      </Subheading>
                      <Text
                        variant="blue"
                        addClassName="mt-5 opacity-40 max-w-md mb-10"
                      >
                        {description}
                      </Text>
                    </TextContainer>
                    <FrameNumber>
                      <Text variant="blue" addClassName="uppercase text-sm">
                        Frame
                      </Text>
                      <Heading variant="modal">#{frame}</Heading>
                    </FrameNumber>
                  </Content>
                </Details>
              </ModalBody>
            </ModalCard>
          </Transition.Child>
        </Overlay>
      </Dialog>
    </Transition.Root>
  );
}

export function BaseModal({ children, isOpen, onClose }) {
  const handleClose = () => {
    if (onClose) onClose();
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <Overlay onClick={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ModalCardBase>{children}</ModalCardBase>
          </Transition.Child>
        </Overlay>
      </Dialog>
    </Transition.Root>
  );
}
