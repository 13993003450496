module.exports = {
  content: ["./src/**/*.{js,jsx}"],
  theme: {
    fontFamily: {
      heading: ["Whitney", "sans-serif"],
      callout: ["Whitney", "sans-serif"],
      body: ["Whitney", "Helvetica", "sans-serif"],
    },
    extend: {
      colors: {
        gray: {
          200: "#F4F4F6",
        },
        slate: {
          100: "#8891AA",
        },
        red: {
          500: "#F22F46",
        },
        yellow: {
          500: "#FFCB51",
        },
        green: {
          500: "#68B585",
        },
        blue: {
          300: "#008CFF",
          500: "#0263E0",
          600: "#001489",
          700: "#001983",
          900: "#030B5D",
        },
      },
      spacing: {
        "1/1": "100%",
        "2/3": "66.666667%",
        "16/8": "50.00%",
        "16/9": "56.25%",
      },
      minHeight: {
        0: "0",
        "1/4": "25%",
        "1/2": "50%",
        "3/4": "75%",
        full: "100%",
      },
    },
  },
  plugins: [],
};
