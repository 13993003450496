import React, { useState } from "react";
import Text from "../../../components/Text";
import Heading from "../../../components/Text";
import Stylewind from "../../../components/Stylewind";
import ImageWithFallback from "../../../components/ImageWithFallback";
import info from "./info.svg";
import classnames from "classnames";

export const Box = Stylewind``;

export const FrameImage = Stylewind`
  width: 198px;
  height: 122px;
  max-width: 198px;
  max-height: 122px;
  background-image: url(${(props) => props.frameImage});
  background-position: center;
  background-size: cover;
  overflow: visible;
  cursor: pointer;

  & > img, & > object {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: -15px;
    left: -16px;
  }
`;

const FrameSkeleton = Object.assign({}, FrameImage);
FrameSkeleton.defaultProps = {
  className: "bg-gray-400 rounded-lg animate-pulse",
};

export const BlockLayout = Stylewind`
  max-width: 198px;
`;
BlockLayout.defaultProps = {
  className: "flex flex-col-reverse",
};

export const AvatarImage = Stylewind``;
AvatarImage.defaultProps = {
  className: "",
  as: "img",
};

FrameImage.defaultProps = {
  className: "rounded-lg bg-white border border-gray-300 relative pixelated",
};

export const TextContent = Stylewind``;
TextContent.defaultProps = {
  className: "flex ml-8 justify-between items-center",
};

export const SkeletonText = Stylewind``;
SkeletonText.defaultProps = {
  as: "div",
  className: "w-full h-full bg-gray-400 border-4 mt-2 ml-2",
};

export const Block = ({ id, name, frame, onSelect, onSelectFrame, cacheBuster, ...rest }) => {
  const [avatarHover, setAvatarHover] = useState(false);

  const avatarUrl = avatarHover
    ? info
    : `${process.env.REACT_APP_S3_PREFIX}heroes/attendee-${id}.png?cache=${cacheBuster}`;
  const frameImage = `${process.env.REACT_APP_S3_PREFIX}spaces/space_${id}.png?cache=${cacheBuster}`;

  return (
    <Box {...rest} addClassName="ml-4">
      {frame === undefined ? (
        <BlockLayout>
          <TextContent>
            <SkeletonText />
            <SkeletonText />
          </TextContent>
          <FrameSkeleton />
        </BlockLayout>
      ) : (
        <BlockLayout>
          <TextContent>
            <Heading addClassName="text-blue-700 text-xs">{name}</Heading>
            <Text addClassName="text-blue-700 text-xs font-bold">{frame}</Text>
          </TextContent>
          <FrameImage frameImage={frameImage} onClick={onSelectFrame}>
            <ImageWithFallback
              className={classnames(
                "bg-white rounded-full w-full h-auto mt-12 border-4",
                { "border-blue-500 bg-blue-500": avatarHover },
                { "border-blue-600": !avatarHover }
              )}
              src={avatarUrl}
              onClick={onSelect}
              onMouseOver={() => setAvatarHover(true)}
              onMouseOut={() => setAvatarHover(false)}
              alt=""
            />
          </FrameImage>
        </BlockLayout>
      )}
    </Box>
  );
};
