import styled from "styled-components";
import classnames from "classnames";

// Merges Tailwind and Styled together. The main thing
// we get out of using this is variants
const Stylewind = styled.div.attrs(
  ({
    variant,
    defaultVariant,
    variantClassNames,
    className,
    addClassName,
    ...rest
  }) => {
    const localVariant = variant || defaultVariant;
    // get the variant from the variantClassNames or default className
    const localClassName = classnames(
      className,
      localVariant && variantClassNames && variantClassNames[localVariant]
    );

    return { ...rest, className: classnames(localClassName, addClassName) };
  }
);

export default Stylewind;
