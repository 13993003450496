import React from "react";
import { BaseModal } from "../screens/Video/components/Modal";
import Heading from "./Heading";
import Text from "./Text";

export default function ErrorDialog({ headingText, bodyText }) {
  return (
    <BaseModal isOpen={true}>
      <div className="w-full p-8">
        <Heading variant="modal" addClassName="text-red-500">
          {headingText}
        </Heading>
        <div className="mt-4">
          <p className="text-slate-500">{bodyText}</p>
        </div>
      </div>
    </BaseModal>
  );
}
