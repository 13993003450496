import React, { useRef, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Stylewind from "../../components/Stylewind";
import Text from "../../components/Text";
import Heading from "../../components/Heading";
import { useItems } from "../../hooks/useItems";
import { FixedSizeList as List } from "react-window";
import { ArtistDisplay } from "./components/ArtistDisplay";
import { ArtistHeadings } from "./components/ArtistHeadings";
import { useTailwindBreakpoint } from "../../hooks/useTailwindBreakpoint";
import GlobalErrorBoundary from "../../components/GlobalErrorBoundary";
import ErrorDialog from "../../components/ErrorDialog";
import { useWindowSize } from "../../hooks/useWindowSize";

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-white absolute left-3 top-3 rotate-90 opacity-30"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
);

const Column =
  ({ items, breakpoint, status, cacheBuster }) =>
  ({ index, style }) => {
    // for mobile we want to employ a standard vertical list (e.g. no left / right)
    if (breakpoint === undefined) {
      return (
        <div style={style} key={index} className="flex space-x-6 px-4">
          <ArtistDisplay
            {...items[index]}
            index={index}
            cacheBuster={cacheBuster}
            isLoading={status !== "loaded"}
          />
        </div>
      );
    }

    const firstItem = items[index * 2];
    const secondItem = items[index * 2 + 1];

    return (
      <div style={style} key={index} className="flex space-x-6 px-4">
        <ArtistDisplay
          {...firstItem}
          index={index}
          cacheBuster={cacheBuster}
          isLoading={status !== "loaded"}
        />
        {status !== "loaded" || secondItem ? (
          <ArtistDisplay
            {...secondItem}
            index={index + 1}
            cacheBuster={cacheBuster}
            isLoading={status !== "loaded"}
          />
        ) : (
          <span className="w-1/2" />
        )}
      </div>
    );
  };

const Container = Stylewind``;
Container.defaultProps = {
  className: "flex flex-col",
};

const SearchBlock = Stylewind``;
SearchBlock.defaultProps = {
  className: "bg-blue-700 flex flex-col md:flex-row",
};

const SearchColumn = Stylewind``;
SearchColumn.defaultProps = {
  className:
    "w-content md:w-1/2 flex flex-col items-center justify-center mt-4 md:mt-0",
};

const Input = Stylewind`
&::placeholder {
  opacity: .5;
}
`;
Input.defaultProps = {
  as: "input",
  type: "text",
  className:
    "rounded-full bg-blue-500 h-12 w-8/12 text-white pl-12 py-4 pr-4 font-body text-lg",
};

const InputContainer = Stylewind``;
InputContainer.defaultProps = {
  className: "md:relative w-full mb-10 md:mb-0 ml-20 md:ml-auto",
};

const Search = ({ onChange }) => {
  return (
    <SearchBlock>
      <SearchColumn>
        <div className="px-16 py-10 hidden md:block">
          <Heading>Artists</Heading>
          <Text addClassName="opacity-50 font-family-body">
            Owl Together is made possible by the creative contributions of
            people just like you! Browse or enter a search term to filter the
            list.
          </Text>
        </div>
      </SearchColumn>
      <SearchColumn>
        <InputContainer>
          <Input
            placeholder="Search"
            onChange={(e) => {
              onChange(e.target.value);
            }}
          ></Input>
          <SearchIcon />
        </InputContainer>
      </SearchColumn>
    </SearchBlock>
  );
};

const ArtistList = ({ items, count, status, cacheBuster }) => {
  const breakpoint = useTailwindBreakpoint();
  const ref = useRef();
  const [dimensions, setDimensions] = useState(undefined);

  useEffect(() => {
    // Next tick 🙃
    window.setTimeout(() => {
      if (ref.current) {
        const { offsetWidth: width, offsetHeight: height } = ref.current;

        setDimensions({ width, height });
      }
    }, 1);
  }, [breakpoint]);

  const shouldRender = dimensions?.height > 0 && count > 0;

  return (
    <div className="bg-white h-full grow-3 w-screen pb-10" ref={ref}>
      {items.length === 0 && status === "loaded" && (
        <div className="w-1/3 bg-gray-300 mx-auto mt-12 flex flex-col items-center justify-center self-center p-12 rounded-md">
          <Heading addClassName="text-blue-800">No artists found</Heading>
          <Text addClassName="text-blue-800">
            Please adjust your search filters
          </Text>
        </div>
      )}
      {shouldRender && (
        <div className="mt-5">
          <ArtistHeadings />
          <List
            height={dimensions.height}
            itemCount={breakpoint === undefined ? count : Math.ceil(count / 2)}
            itemSize={50}
            layout="vertical"
            width={dimensions.width}
          >
            {Column({ items, breakpoint, status, cacheBuster })}
          </List>
        </div>
      )}
    </div>
  );
};

const Artists = () => {
  const { frames, status, count, cacheBuster } = useItems();
  const [filter, setFilter] = useState("");

  // if there is a filter, filter loaded items.
  const filteredItems = frames.filter((item) => {
    if (filter && (!item || item.isLoading || !item.name)) {
      return false;
    }

    return filter
      ? item?.name.toLowerCase().includes(filter.toLowerCase())
      : true;
  });

  return (
    <Layout>
      <GlobalErrorBoundary>
        <Container>
          <Search
            onChange={(value) => {
              setFilter(value);
            }}
          />
          {status === "error" && (
            <ErrorDialog
              headingText="Error loading artists"
              bodyText="We've encountered a problem loading data. We're working to resolve this issue!"
            />
          )}
          {count > 0 && (
            <ArtistList
              items={filteredItems}
              key={status}
              count={status === "loaded" ? filteredItems.length : count}
              status={status}
              cacheBuster={cacheBuster.current}
            />
          )}
        </Container>
      </GlobalErrorBoundary>
    </Layout>
  );
};

export default Artists;
