import Stylewind from "./Stylewind";

export const Heading = Stylewind``;
Heading.defaultProps = {
  className: "text-white font-heading uppercase font-semibold",
  as: "h2",
  defaultVariant: "primary",
  variantClassNames: {
    primary: "text-3xl",
    blue: "text-blue-300 mb-3 text-2xl",
    modal: "text-blue-700 text-3xl",
  },
};

export default Heading;
