import React, { useRef, useEffect, createRef, useState } from "react";
import Stylewind from "../../../components/Stylewind";
import { useWindowSize } from "../../../hooks/useWindowSize";

const List = Stylewind`
  list-style-type: none;
  display: flex;
  min-height: 50px;
`;

List.defaultProps = {
  as: "ul",
  className: "my-4",
};

const ListItem = Stylewind`
  &.elevated > .box {
    --height: 3px;
    height: var(--height);
    transition: height 200ms;
  }

  & > .box {
    margin: 0 2px;
  }

  &.active > .box {
    --height: 40px;
  }

  &.sibling-1 > .box {
    --height: 20px;
  }
  &.sibling-2 > .box {
    --height: 10px;
  }

  &.sibling-3 > .box {
    --height: 5.5px;
  }

  &.sibling-4 > .box {
    --height: 2.5px;
  }
`;

ListItem.defaultProps = {
  as: "li",
};

const Box = Stylewind`
  height: 3px;
  width: 7px;
`;
Box.defaultProps = {
  as: "div",
  className: "box bg-blue-700",
};

const Item = ({ onHover, selectedIndex, index, onSelect }) => {
  let realDistanceFromActive = selectedIndex - index;
  if (realDistanceFromActive < 0) {
    realDistanceFromActive = realDistanceFromActive * -1;
  }

  const className =
    realDistanceFromActive === 0
      ? "elevated active"
      : `elevated sibling-${realDistanceFromActive}`;

  return (
    <ListItem
      onMouseOver={onHover}
      addClassName={`${className} cursor-pointer`}
      onClick={() => onSelect(index)}
    >
      <Box />
    </ListItem>
  );
};

export const TimelineSelectorBase = ({ items = 50, onSelect }) => {
  const [activeIndex, setActiveIndex] = React.useState(undefined);

  const itemArray = Array.from(Array(items).keys());

  // will contain the timer for handling when to fade the active item out
  let overTimer = null;

  return (
    <List
      onMouseOver={() => {
        overTimer && window.clearTimeout(overTimer);
      }}
      onMouseOut={() => {
        overTimer = window.setTimeout(() => {
          setActiveIndex(undefined);
        }, 1000);
      }}
    >
      {itemArray.map((item, index) => (
        <Item
          key={index}
          index={index}
          selectedIndex={activeIndex}
          onSelect={onSelect}
          onHover={() => {
            setActiveIndex(index);
          }}
        />
      ))}
    </List>
  );
};

export const TimelineSelector = ({ maxItems, onSelect }) => {
  const { width } = useWindowSize();
  const [items, setItems] = useState(50);
  const ref = useRef();

  useEffect(() => {
    // get element width
    const width = ref.current.offsetWidth;

    // divide element width by 11 (approx overall width of one item)
    const localItems = Math.floor(width / 11);

    setItems(localItems);
  }, [width]);

  const localMaxItems = maxItems > items ? maxItems : items;

  return (
    <div ref={ref} className="hidden md:block">
      <TimelineSelectorBase
        items={localMaxItems}
        onSelect={(index) => onSelect({ index, maxIndex: localMaxItems })}
      />
    </div>
  );
};

export default TimelineSelector;
