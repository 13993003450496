import React from "react";
import Stylewind from "../Stylewind";
import { useNavigate } from "react-router-dom";

const Link = Stylewind`
  &.link-active {
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
  }
`;

Link.defaultProps = {
  as: "a",
  className: "font-heading text-sm md:text-lg font-semibold uppercase",
  defaultVariant: "primary",
  variantClassNames: {
    primary: "text-blue-900",
    active: "underline link-active text-blue-900",
    footer: "text-white font-callout",
  },
};

// className="flex justify-between items-center"
const NavContainer = Stylewind`
  width: 250px;
`;

NavContainer.defaultProps = {
  as: "nav",
};

const NavList = Stylewind``;
NavList.defaultProps = {
  as: "ul",
  className:
    "list-none flex flex-1 flex-row justify-between items-center min-w-full",
};

// If an onClick handler is passed in, wrap the component in a button
// otherwise return a standard anchor tag
export const NavItem = ({ to, onClick, children, isActive, variant }) => {
  // if we have variant, use that
  // otherwise check if active or treat it like no variant is present
  const localVariant = variant ? variant : isActive ? "active" : "primary";
  const navigate = useNavigate();
  if (onClick) {
    return (
      <Link as="button" onClick={onClick} variant={localVariant}>
        {children}
      </Link>
    );
  }

  return (
    <Link
      href={to}
      variant={localVariant}
      onClick={(e) => {
        e.preventDefault();
        navigate(to);
      }}
    >
      {children}
    </Link>
  );
};

export const Nav = ({ children, variant }) => {
  return (
    <NavContainer>
      <NavList>
        {React.Children.map(children, (child) => (
          <li key={child.props.to}>{React.cloneElement(child, { variant })}</li>
        ))}
      </NavList>
    </NavContainer>
  );
};
Nav.Item = NavItem;

export default Nav;
