import React from "react";
import Stylewind from "../../../components/Stylewind";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { useTailwindBreakpoint } from "../../../hooks/useTailwindBreakpoint";
import ImageWithFallback from "../../../components/ImageWithFallback";

const SkeletonBlock = Stylewind`
  height: 10px;
`;

SkeletonBlock.defaultProps = {
  className: "w-full h-full bg-gray-300 animate-pulse mr-2 ml-2",
};

export const Content = Stylewind`
  & > :first-child{
    width: 40%;
  }

  & > :nth-child(2), & > :nth-child(3) {
    width: 30%;
  }
`;
Content.defaultProps = {
  className: "flex mx-6 items-center h-full w-full pr-12 cursor-pointer",
};

const Block = Stylewind`
  height: 41px;

`;
Block.defaultProps = {
  className:
    "bg-gray-200 text-blue-700 rounded-md box-border mb-2 hover:bg-yellow-500",
};

const First = Stylewind``;
First.defaultProps = {
  as: "div",
  className: "flex items-center space-x-4 h-full min-h-full",
};

const Last = Stylewind``;
Last.defaultProps = {
  as: "div",
  className: "flex items-center justify-between",
};

const RoundedButton = Stylewind`
  border: 1px solid;
  &:hover {
    svg{
      color: #fff;
    }
  }
`;
RoundedButton.defaultProps = {
  as: "button",
  className:
    "rounded-full w-8 h-8 border-blue-700 flex items-center justify-center hover:bg-green-500 hover:text-white hover:fill-white",
};

export const ArtistDisplay = ({
  id,
  name,
  slack: handle,
  frame,
  url,
  isLoading,
  index,
  cacheBuster,
}) => {
  const breakpoint = useTailwindBreakpoint();

  const navigate = useNavigate();
  const avatarUrl = `${process.env.REACT_APP_S3_PREFIX}heroes/attendee-${id}.png?cache=${cacheBuster}`;
  const localHandle =
    handle && (handle.indexOf("@") > -1 ? handle : `@${handle}`);
  return (
    <div className={breakpoint === undefined ? "w-full" : "w-1/2"}>
      <Block key={`block-${index}`}>
        {isLoading ? (
          <Content>
            <First>
              <SkeletonBlock />
            </First>
            <SkeletonBlock />
            <Last>
              <SkeletonBlock />
            </Last>
          </Content>
        ) : (
          <Content onClick={() => {
            navigate(`/?visibleFrame=${id}`);
          }}>
            <First>
              <ImageWithFallback
                src={avatarUrl}
                className="rounded-full w-8 h-8 border-2 border-blue-700 bg-white"
                alt={`${name}'s avatar`}
              />
              <span>{name}</span>
            </First>
            <span>{localHandle}</span>
            <Last>
              <strong>{frame}</strong>
              <RoundedButton>
                <ArrowNarrowRightIcon className="text-blue-700 w-4 h-4 font-extralight" />
              </RoundedButton>
            </Last>
          </Content>
        )}
      </Block>
    </div>
  );
};
