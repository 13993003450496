import Video from "./screens/Video";
import Artists from "./screens/Artists";
import About from "./screens/About";
import ItemsProvider from "./components/ItemsProvider";

import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <ItemsProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Video />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </BrowserRouter>
    </ItemsProvider>
  );
}

export default App;
