import { useState, useEffect } from "react";
import { useQueryState } from "./useQuery";

const S3_URL = process.env.REACT_APP_S3_PREFIX;
const FRAME_PATH = "nnft/nnft";
const COUNT_PATH = "nnft/frame-count";

const FRAME_URL = process.env.REACT_APP_NNFT_FRAME_PREFIX;

export const getFrame = async (visibleFrame) => {
  // Default options are marked with *
  const response = await fetch(`${FRAME_URL}${visibleFrame}`);
  const frame = await response.json();

  return frame;
};

export const useGetFrame = (id, loadCondition) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (loadCondition) {
    }
  }, []);

  return item;
};

const statusTypes = {
  loading: "loading",
  loadedOne: "loadedOne",
  loaded: "loaded",
  error: "error",
};

export const useLoadItems = () => {
  const [state, setState] = useState({
    status: statusTypes.loading,
    frames: [],
    count: 0,
  });
  const [visibleFrame] = useQueryState("visibleFrame");

  useEffect(() => {
    async function loadCount() {
      const response = await fetch(`${S3_URL}${COUNT_PATH}`);

      if (state.status !== statusTypes.loaded) {
        const body = await response.json();

        setState((previousState) =>
          previousState.status !== statusTypes.loaded
            ? { ...previousState, count: body.frameCount }
            : previousState
        );
      }
    }

    async function loadAllData() {
      // Default options are marked with *
      const response = await fetch(
        `${S3_URL}${FRAME_PATH}?date=${new Date().toISOString()}`
      );

      const frames = await response.json();

      setState((previousState) => {
        try {
          // when we have a visible frame, merge it back into the collection
          if (visibleFrame) {
            const frameIndex = frames.findIndex(
              (frame) => frame.id === visibleFrame
            );

            // If the frame isn't found, add it to the end of the list
            // if the frame is found, replace the frame in the collection with what we have from the API
            const replaceIndex = frameIndex === -1 ? frames.length : frameIndex;

            if (previousState.frames[0]) {
              frames[replaceIndex] = previousState.frames[0];
            }
          }
        } catch (ex) {}

        return {
          status: statusTypes.loaded,
          frames: frames,
          count: frames.length,
        };
      });
    }

    async function loadFrame() {
      if (visibleFrame && state.status !== statusTypes.loaded) {
        const frame = await getFrame(visibleFrame);
        if (state.status !== statusTypes.loaded) {
          setState((current) => ({
            ...current,
            status: statusTypes.loadedOne,
            frames: [frame],
          }));
        }
      }
    }

    try {
      loadCount();
      loadFrame();
      loadAllData();
    } catch (ex) {
      setState({ ...state, status: statusTypes.error });
    }
  }, []);

  return state;
};
