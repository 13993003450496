import { useRef, useEffect } from 'react'

const useCanvas = (draw, options ={}) => {

  const canvasRef = useRef(null)

  useEffect(() => {

    const canvas = canvasRef.current
    const context = canvas.getContext(options.context || '2d')
    const { width, height } = options
    let frameCount = 0
    let animationFrameId

    const resizeContextAndCanvas = (context, canvas) => {
      if (canvas.width !== width || canvas.height !== height) {
        // const { devicePixelRatio:ratio=1 } = window
        const ratio = 1
        canvas.width = width*ratio
        canvas.height = height*ratio
        context.scale(ratio, ratio)
        return true
      }

      return false
    }

    const preDraw = (context, canvas) => {
      context.save()

      resizeContextAndCanvas(context, canvas)
      const { width, height } = context.canvas
      context.clearRect(0, 0, width, height)
    }

    const postDraw = () => {
      context.restore()
    }

    const render = () => {
      preDraw(context, canvas)
      frameCount++
      draw(context, frameCount)
      postDraw()
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw, options])

  return canvasRef
}

export default useCanvas
